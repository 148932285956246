import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import dashoardSlice from '../slices/dashboard.slice';
import Api from '../../services/Apis/index';
import loaderSlice from '../slices/loader.slice';

function* getDashboardData(): any {
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.authentication.getDashboardData();
    if (!response.data.error) {
      yield put(dashoardSlice.actions.setDashboardData(response.data));
    } else if (response.data.error) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('no data found');
    yield put(loaderSlice.actions.hide());
  }
}
function* getDashboardGraphData(action: any): any {
  const values: any = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.authentication.getDashboardGraphData(values);
    if (!response.data.error) {
      yield put(dashoardSlice.actions.setDashboardGraphData(response.data.data));
    } else if (response.data.error) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('no data found');
    yield put(loaderSlice.actions.hide());
  }
}
function* dashboardSaga() {
  yield takeEvery('dashboard/getDashboardData', getDashboardData);
  yield takeEvery('dashboard/getDashboardGraphData', getDashboardGraphData);
}

export default dashboardSaga;
