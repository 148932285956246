/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint no-unused-vars: "error" */

import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  dashboardData: any;
  dashboardGraphData: any
};

const initialState: InitialState = {
  dashboardData: '',
  dashboardGraphData: '',
};

export const getDashboardData = () => {};
export const setDashboardData = (state: InitialState, action: any) => {
  state.dashboardData = action.payload;
};
export const getDashboardGraphData = (state: InitialState, action: any) => {
};
export const setDashboardGraphData = (state: InitialState, action: any) => {
  state.dashboardGraphData = action.payload;
};
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboardData,
    setDashboardData,
    getDashboardGraphData,
    setDashboardGraphData,
  },
});

export default dashboardSlice;
