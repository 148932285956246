/* eslint-disable require-yield */
import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Api from '../../services/Apis/index';
import userSlice from '../slices/users.slice';
import loaderSlice from '../slices/loader.slice';

function* handleError(err: any) {
  if (err?.response) {
    if (err?.response?.status && err?.response?.data?.msg && err?.response?.data?.error) {
      toast.error(err?.response?.data?.msg);
    }
  } else if (err.message) {
    toast.error(err?.response?.data?.msg);
  }
}

function* getUser(): any {
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.authentication.getUser();
    if (!response.data.error) {
      yield put(userSlice.actions.setUserResponse(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* inviteUser(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.inviteUser(values);
    if (response.data) {
      yield put(userSlice.actions.getUser());
      toast.success('Invite sent successfully');
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* assignDoor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.assignDoor(values);
    if (response.data) {
      toast.success('door assigned successfully');
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* resendInvite(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.resendInvite(values);
    if (response.data) {
      toast.success(response.data.msg);
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* removeUser(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.removeUser(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(userSlice.actions.getUser());
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* updateRole(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.updateRole(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(userSlice.actions.getUser());
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* userSaga() {
  yield takeEvery('user/getUser', getUser);
  yield takeEvery('user/inviteUser', inviteUser);
  yield takeEvery('user/assignDoor', assignDoor);
  yield takeEvery('user/resendInvite', resendInvite);
  yield takeEvery('user/removeUser', removeUser);
  yield takeEvery('user/updateRole', updateRole);
}

export default userSaga;
