import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { navigator } from '../../navigation/navigator';
import authSlice from '../slices/auth.slice';
import Api from '../../services/Apis/index';
import loaderSlice from '../slices/loader.slice';

function* login(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.signIn(values);
    if (response.data.data) {
      yield put(authSlice.actions.setAuthentication(response.data.data.accessToken));
      localStorage.setItem('token', response.data.data.accessToken);
      localStorage.setItem('user', response.data.data.name);
      localStorage.setItem('role', response.data.data.role);
      navigator.history.replace('/dashboard');
    } else {
      toast.error('Invalid username or password');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('Invalid username or password');
    yield put(loaderSlice.actions.hide());
  }
}

function* logOut() {
  try {
    yield put(authSlice.actions.clearToken());
    navigator.history.replace('/');
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* signUp(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.signUp(values);
    if (!response.data.error) {
      navigator.history.replace('/login', response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error(response.data.msg);
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* userSignUp(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.userSignUp(values);
    if (!response.data.error) {
      navigator.history.replace('/login');
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error(response.data.msg);
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* checkauth() {
  const pathName = navigator?.history?.location?.pathname;
  try {
    yield put(loaderSlice.actions.show());
    const token = localStorage.getItem('token');
    if (token) {
      yield put(authSlice.actions.setAuthentication(token));
      navigator.history.replace(pathName);
      yield put(loaderSlice.actions.hide());
    } else {
      navigator.history.replace('/dashboard');
      yield put(loaderSlice.actions.hide());
      toast.error('Please Login');
    }
  } catch (err) {
    if (pathName.match('/new')) {
      navigator.history.replace('/login');
    }
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* authSaga() {
  yield takeEvery('auth/login', login);
  yield takeEvery('auth/signUp', signUp);
  yield takeEvery('auth/userSignUp', userSignUp);
  yield takeEvery('auth/logOut', logOut);
  yield takeEvery('auth/checkauth', checkauth);
}

export default authSaga;
