/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';

function* getAttachedDevice(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getAttachedDoors`,
    {
      params: {
        limit: values.limit,
        pageNo: values.pageNo,
        search: values.search,
      },
    });
}
function* getUnAttachedDevice(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getUnattachedDoors`,
    {
      params: {
        limit: values.limit,
        pageNo: values.pageNo,
        search: values.search,
      },
    });
}
function* updateDoorClient(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/door/updateDoorClient`, values);
}
const device = {
  getAttachedDevice,
  getUnAttachedDevice,
  updateDoorClient,
};

export default device;
