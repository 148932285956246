/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import authentication from './authentication/Authentication';
import device from './device/Device';

const Api = {
  authentication,
  device,
};

export default Api;
