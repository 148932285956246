/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-param-reassign */
/* eslint no-unused-vars: "error" */

import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  location: any;
  locationResponse: any;
  departmentResponse: any,
  building: any;
  department: any,
  door: any;
  doorByDepartment: any,
  device: any;
  floor: any,
  buildingByLocation: any;
  buildingResponse: any;
  floorResponse: any,
  doorResponse: any;
  ClientLocationByIdResponse: any;
  ClientBuildingByIdResponse: any;
  ClientDoorByIdResponse: any;
  ClientDepartmentByIdResponse: any,
  doorUser: any;
  doorLogs: any;
  handleTreeView: any,
  ClientFloorByIdResponse: any,
  sensorResponse: any,
  keysPopUp: any,
  keys: any,
  treeView:
    | any
    | {
        locationName: string;
        type: string;
        building: any;
      }
    | undefined;
};

const initialState: InitialState = {
  keysPopUp: false,
  keys: {},
  location: '',
  building: '',
  door: '',
  doorByDepartment: '',
  device: '',
  floor: '',
  department: '',
  locationResponse: '',
  sensorResponse: '',
  ClientFloorByIdResponse: [],
  ClientDepartmentByIdResponse: [],
  floorResponse: '',
  buildingResponse: '',
  departmentResponse: '',
  buildingByLocation: [],
  doorResponse: '',
  treeView: [],
  ClientLocationByIdResponse: [],
  ClientBuildingByIdResponse: [],
  ClientDoorByIdResponse: [],
  doorUser: [],
  doorLogs: [],
  handleTreeView: [],
};
export const getDoorLogs = (state: InitialState, action: any) => {
};
export const setDoorLogs = (state: InitialState, action: any) => {
  state.doorLogs = action.payload;
};
export const addLocation = (state: InitialState, action: any) => {};
export const setAddLocation = (state: InitialState, action: any) => {
  state.location = action.payload;
};
export const setaddLocationResponse = (state: InitialState, action: any) => {
  state.locationResponse = action.payload;
};
export const setBuildingResponse = (state: InitialState, action: any) => {
  state.buildingResponse = action.payload;
};
export const setSensorResponse = (state: InitialState, action: any) => {
  state.sensorResponse = action.payload;
};
export const setDoorResponse = (state: InitialState, action: any) => {
  state.doorResponse = action.payload;
};
export const setAddLocationTree = (state: InitialState, action: any) => {
  const treeView = action.payload.map((item: any) => {
    return { ...item, type: 'location', children: [] };
  });
  state.treeView = treeView;
};
export const addBuilding = (state: InitialState, action: any) => {};
export const getBuilding = () => {};
export const setBuilding = (state: InitialState, action: any) => {
  state.building = action.payload;
};
export const getLocation = () => {};
export const getLocationTree = () => {};
export const addDoor = (state: InitialState, action: any) => {};
export const getDoor = (state: InitialState, action: any) => {};
export const getDoorByDepartment = (state: InitialState, action: any) => {};
export const getDevice = (state: InitialState, action: any) => {};
export const setAddDoor = (state: InitialState, action: any) => {
  state.door = action.payload;
};
export const setAddDoorByDepartment = (state: InitialState, action: any) => {
  state.doorByDepartment = action.payload;
};
export const setAddDoorUser = (state: InitialState, action: any) => {
  state.doorUser = action.payload;
};
export const setAddDevice = (state: InitialState, action: any) => {
  state.device = action.payload;
};
export const addDevice = (state: InitialState, action: any) => {};
export const getBuildingByLocation = (state: InitialState, action: any) => {};
export const setAddBuildingByLocation = (state: InitialState, action: any) => {
  state.buildingByLocation = action.payload;
};
export const getBuildingByLocationTree = (
  state: InitialState,
  action: any,
) => {};
export const setAddBuildingByLocationTree = (
  state: InitialState,
  action: any,
) => {
  const building = action.payload.response.map((item: any) => {
    return { ...item, type: 'building', children: [] };
  });
  state.buildingByLocation = action.payload.response;
  state.treeView[action.payload.index].children = building;
};
export const getDoorByLocationTree = (state: InitialState, action: any) => {};
export const getDepartmentByLocationTree = (state: InitialState, action: any) => {};
export const getFloorByLocationTree = (state: InitialState, action: any) => {};
export const setAddDoorTree = (state: InitialState, action: any) => {
  const door = action.payload.response.map((item: any) => {
    return { ...item, type: 'door', children: [] };
  });
  state.treeView[action.payload.index].children[
    action.payload.buildingIndex
  ].children[action.payload.floorIndex].children[action.payload.departmentIndex].children = door;
  // state.treeView[action.payload.index].children[
  //   action.payload.buildingIndex
  // ].children = door;
};
export const setAddDepartmentTree = (state: InitialState, action: any) => {
  const department = action.payload.response.map((item: any) => {
    return { ...item, type: 'department', children: [] };
  });
  state.treeView[action.payload.index].children[
    action.payload.buildingIndex
  ].children[action.payload.floorIndex].children = department;
};
export const setAddFloorTree = (state: InitialState, action: any) => {
  const door = action.payload.response.map((item: any) => {
    return { ...item, type: 'floor', children: [] };
  });
  state.treeView[action.payload.index].children[
    action.payload.buildingIndex
  ].children = door;
};
export const getDeviceByLocationTree = (state: InitialState, action: any) => {};
export const getClientLocationById = (state: InitialState, action: any) => {};
export const getClientDepartmentById = (state: InitialState, action: any) => {};
export const setAddDeviceTree = (state: InitialState, action: any) => {
  const device = action.payload.response.map((item: any) => {
    return { ...item, type: 'device' };
  });
  state.treeView[action.payload.index].children[
    action.payload.buildingIndex
  ].children[action.payload.floorIndex].children[action.payload.departmentIndex].children[
    action.payload.doorIndex].children = device;
};
export const setClientLocationById = (state: InitialState, action: any) => {
  state.ClientLocationByIdResponse = action.payload;
};
export const getClientBuildingById = (state: InitialState, action: any) => {};
export const getClientFloorById = (state: InitialState, action: any) => {};
export const setBuildingLocationById = (state: InitialState, action: any) => {
  state.ClientBuildingByIdResponse = action.payload;
};
export const setFloorById = (state: InitialState, action: any) => {
  state.ClientFloorByIdResponse = action.payload;
};
export const setDepartmentById = (state: InitialState, action: any) => {
  state.ClientDepartmentByIdResponse = action.payload;
};
export const getDoorUser = (state: InitialState, action: any) => {};
export const getClientDoorById = (state: InitialState, action: any) => {};
export const setDoorLocationById = (state: InitialState, action: any) => {
  state.ClientDoorByIdResponse = action.payload;
};

export const editLocation = (state: InitialState, action: any) => {};
export const editBuilding = (state: InitialState, action: any) => {};
export const editDepartment = (state: InitialState, action: any) => {};
export const editFloor = (state: InitialState, action: any) => {};
export const editDoor = (state: InitialState, action: any) => {};
export const editSensor = (state: InitialState, action: any) => {};
export const deleteLocation = (state: InitialState, action: any) => {};
export const deleteBuilding = (state: InitialState, action: any) => {};
export const deleteDoor = (state: InitialState, action: any) => {};
export const deleteSensor = (state: InitialState, action: any) => {};
export const assignUnassignedDoor = (state: InitialState, action: any) => {};
export const resetDoorOpeningCount = (state: InitialState, action: any) => {};
export const addDepartment = (state: InitialState, action: any) => {};
export const getDepartment = (state: InitialState, action: any) => {};
export const deleteDepartment = (state: InitialState, action: any) => {};
export const addFloor = (state: InitialState, action: any) => {};
export const getFloor = (state: InitialState, action: any) => {};
export const deleteFloor = (state: InitialState, action: any) => {};
export const setaddDepartmentResponse = (state: InitialState, action: any) => {
  state.departmentResponse = action.payload;
};
export const setAddDepartment = (state: InitialState, action: any) => {
  state.department = action.payload;
};
export const setaddFloorResponse = (state: InitialState, action: any) => {
  state.floorResponse = action.payload;
};
export const setAddFloor = (state: InitialState, action: any) => {
  state.floor = action.payload;
};
export const handleTreeview = (state: InitialState, action: any) => {
  const data = {
    item: action.payload.item,
    index: action.payload.index,
    buildingIndex: action.payload.buildingIndex,
    floorIndex: action.payload.floorIndex,
    doorIndex: action.payload.doorIndex,
  };
  state.handleTreeView = data;
};
export const getDoorByDepartmentTree = (state: InitialState, action: any) => {};
export const setKeys = (state: InitialState, action: any) => {
  state.keys = action.payload;
};
export const setKeysPopUp = (state: InitialState, action: any) => {
  state.keysPopUp = action.payload;
};
const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    addLocation,
    setAddLocation,
    getLocation,
    addBuilding,
    getBuilding,
    setBuilding,
    addDoor,
    getDoor,
    setAddDoor,
    setAddDoorByDepartment,
    setAddDoorUser,
    setAddDevice,
    getDevice,
    addDevice,
    getLocationTree,
    getBuildingByLocation,
    setAddBuildingByLocation,
    setAddLocationTree,
    getDepartmentByLocationTree,
    getBuildingByLocationTree,
    setAddBuildingByLocationTree,
    getDoorByLocationTree,
    getFloorByLocationTree,
    setAddDoorTree,
    setAddFloorTree,
    setAddDepartmentTree,
    getDeviceByLocationTree,
    setAddDeviceTree,
    setaddLocationResponse,
    setBuildingResponse,
    setDoorResponse,
    getClientLocationById,
    getClientDepartmentById,
    setClientLocationById,
    getClientBuildingById,
    getClientFloorById,
    setBuildingLocationById,
    getDoorUser,
    getClientDoorById,
    setDoorLocationById,
    editLocation,
    editBuilding,
    editDoor,
    editSensor,
    deleteLocation,
    deleteBuilding,
    deleteDoor,
    deleteSensor,
    assignUnassignedDoor,
    setDoorLogs,
    getDoorLogs,
    resetDoorOpeningCount,
    addDepartment,
    getDepartment,
    deleteDepartment,
    addFloor,
    getFloor,
    deleteFloor,
    setaddDepartmentResponse,
    setAddDepartment,
    setaddFloorResponse,
    setAddFloor,
    handleTreeview,
    getDoorByDepartmentTree,
    setFloorById,
    setDepartmentById,
    setSensorResponse,
    editDepartment,
    editFloor,
    getDoorByDepartment,
    setKeys,
    setKeysPopUp,
  },
});

export default locationSlice;
