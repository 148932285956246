/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';

function* signIn(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/signIn`, values);
}
function* signUp(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/signUp`, values);
}
function* userSignUp(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/clientUserSignUp`, values);
}
function* addLocation(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/location/addLocation`, values);
}
function* getBuilding(): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getAllBuildings`);
}
function* getLocation(): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getAllLocations`);
}
function* addBuilding(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/building/addBuilding`, values);
}
function* addDoor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/door/createDoor`, values);
}
function* getDoor(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getDoorByDepartment`, {
    params: {
      departmentId: values,
    },
  });
}
function* getDoorByDepartment(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getDoorByDepartment`, {
    params: {
      departmentId: values,
    },
  });
}
function* addDevice(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/sensor/addSensor`, values);
}
function* getBuildingByLocation(values: any): any {
  return yield request.get(
    `${APP_ENTRYPOINT}/client/getAllBuildingsInLocation`,
    {
      params: {
        id: values,
      },
    },
  );
}
function* getDevice(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getAllDevicesInDoor`, {
    params: {
      id: values,
    },
  });
}
function* getUser(): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getAllClientUsers`);
}
function* inviteUser(values: any): any {
  return yield request.post(
    `${APP_ENTRYPOINT}/client/inviteClientUser`,
    values,
  );
}
function* assignDoor(values: any): any {
  return yield request.post(
    `${APP_ENTRYPOINT}/client/assignDoorToUser`,
    values,
  );
}
function* getClientLocationById(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getClientLocationById`, {
    params: {
      locationId: values,
    },
  });
}
function* getClientBuildingById(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getClientBuildingById`, {
    params: {
      buildingId: values,
    },
  });
}
function* getClientFloorById(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getClientFloorById`, {
    params: {
      floorId: values,
    },
  });
}
function* getClientDepartmentById(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getClientDepartmentById`, {
    params: {
      departmentId: values,
    },
  });
}
function* getDoorUser(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getAllDoorsForUser`, {
    params: {
      buildingId: values.buildingId,
      clientUsersId: values.clientUsersId,
    },
  });
}
function* resendInvite(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/resendInvite`, values);
}
function* removeUser(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/removeUser`, values);
}
function* updateRole(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/updateRole`, values);
}
function* getClientDoorById(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getDoorById`, {
    params: {
      doorId: values,
    },
  });
}
function* assignUnAssignedDoor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/assignDoor`, values);
}
function* getDashboardData(): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/dashboardData`);
}
function* getDashboardGraphData(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/dashboardGraphData`, {
    params: {
      limit: values,
    },
  });
}
function* editLocation(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/updateLocation`, values);
}
function* editBuilding(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/updateBuilding`, values);
}
function* editDoor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/updateDoor`, values);
}
function* editSensor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/updateSensor`, values);
}
function* deleteLocation(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/removeLocation`, values);
}
function* deleteBuilding(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/removeBuilding`, values);
}
function* deleteDoor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/door/removeDoor`, values);
}
function* deleteSensor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/sensor/removeSensor`, values);
}
function* assignUnassignedDoor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/assignDoorToMultipleUsers`, values);
}
function* getDoorLogs(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/client/getDoorLogs?limit=5&pageNo=1`, {
    params: {
      doorId: values,
    },
  });
}
function* resetDoorOpeningCount(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/resetDoorCount`, values);
}
function* addDepartment(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/department/addDepartment`, values);
}
function* getDepartment(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/department/getAllDepartmentsInFloor`, {
    params: {
      floorId: values,
    },
  });
}
function* deleteDepartment(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/removeDepartment`, values);
}
function* addFloor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/floor/addFloor`, values);
}
function* getFloor(values: any): any {
  return yield request.get(`${APP_ENTRYPOINT}/floor/getAllFloorsInBuilding`, {
    params: {
      buildingId: values,
    },
  });
}
function* deleteFloor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/floor/removeFloor`, values);
}
function* editDepartment(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/updateDepartment`, values);
}
function* editFloor(values: any): any {
  return yield request.post(`${APP_ENTRYPOINT}/client/updateFloor`, values);
}
const authentication = {
  signIn,
  signUp,
  userSignUp,
  addLocation,
  getBuilding,
  getLocation,
  addBuilding,
  addDoor,
  getDoor,
  getDoorByDepartment,
  addDevice,
  getBuildingByLocation,
  getDevice,
  getUser,
  inviteUser,
  assignDoor,
  getClientLocationById,
  getClientBuildingById,
  getClientFloorById,
  getClientDepartmentById,
  getDoorUser,
  resendInvite,
  removeUser,
  updateRole,
  getClientDoorById,
  assignUnAssignedDoor,
  getDashboardData,
  getDashboardGraphData,
  editLocation,
  editBuilding,
  editDoor,
  editSensor,
  deleteLocation,
  deleteBuilding,
  deleteDoor,
  deleteSensor,
  assignUnassignedDoor,
  getDoorLogs,
  resetDoorOpeningCount,
  addDepartment,
  getDepartment,
  deleteDepartment,
  addFloor,
  getFloor,
  deleteFloor,
  editDepartment,
  editFloor,
};

export default authentication;
