/* eslint-disable require-yield */
import { takeEvery, put } from 'redux-saga/effects';
// import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Api from '../../services/Apis/index';
import loaderSlice from '../slices/loader.slice';
import locationSlice from '../slices/location.slice';
// import { RootState } from '../store/rootReducer';

function* handleError(err: any) {
  if (err?.response) {
    if (
      err?.response?.status
      && err?.response?.data?.msg
      && err?.response?.data?.error
    ) {
      toast.error(err?.response?.data?.msg);
    }
  } else if (err.message) {
    toast.error(err?.response?.data?.msg);
  } else if (err.msg) {
    toast.error(err?.response?.data?.msg);
  }
}

function* addLocation(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.addLocation(values);
    if (!response.data.error) {
      toast.success('Location added successfully');
      yield put(locationSlice.actions.getLocationTree());
      yield put(
        locationSlice.actions.setaddLocationResponse(response.data.data),
      );
    } else if (response.data.error) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getBuilding(): any {
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.authentication.getBuilding();
    if (response.data.data) {
      yield put(locationSlice.actions.setBuilding(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getLocation(): any {
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.authentication.getLocation();
    if (response.data.data) {
      yield put(locationSlice.actions.setAddLocation(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* addBuilding(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.addBuilding(values.data);
    if (!response.data.error) {
      if (values.locationIndex !== '') {
        const data = {
          id: response.data.data.clientLocationId,
          type: 'location',
          index: values.locationIndex,
        };
        yield put(locationSlice.actions.getBuildingByLocationTree(data));
      }
      yield put(locationSlice.actions.setBuildingResponse(response.data.data));
      toast.success('Building added successfully');
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* addDoor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.addDoor(values.data);
    if (!response.data.error) {
      if (values.locationIndex !== '' && values.buildingIndex !== '') {
        const data = {
          id: response.data.data.clientBuildingId,
          type: 'building',
          index: values.locationIndex,
          buildingIndex: values.buildingIndex,
        };
        yield put(locationSlice.actions.getDoorByLocationTree(data));
      }
      yield put(locationSlice.actions.setDoorResponse(response.data.data));
      toast.success('Door added successfully');
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getDoor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.getDoor(values);

    if (response.data.data) {
      yield put(locationSlice.actions.setAddDoor(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getDoorByDepartment(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.getDoorByDepartment(values);

    if (response.data.data) {
      yield put(locationSlice.actions.setAddDoorByDepartment(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getDevice(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.getDevice(values);
    if (!response.data.error) {
      yield put(locationSlice.actions.setAddDevice(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(locationSlice.actions.setAddDevice(''));
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* addDevice(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.addDevice(values.data);
    if (!response.data.error) {
      toast.success('Device added successfully');
      yield put(locationSlice.actions.setSensorResponse(response.data.data));
      if (values.treeView[action.payload.locationIndex].children.length > 0) {
        if (
          values.treeView[action.payload.locationIndex].children[
            action.payload.buildingIndex
          ].children.length > 0
        ) {
          if (
            values.treeView[action.payload.locationIndex].children[
              action.payload.buildingIndex
            ].children[action.payload.floorIndex].children.length > 0
          ) {
            if (
              values.treeView[action.payload.locationIndex].children[
                action.payload.buildingIndex
              ].children[action.payload.floorIndex].children[action.payload.departmentIndex
              ].children.length > 0
            ) {
              if (
                values.treeView[action.payload.locationIndex].children[
                  action.payload.buildingIndex
                ].children[action.payload.floorIndex].children[action.payload.departmentIndex
                ].children[action.payload.doorIndex].children.length > 0
              ) {
                if (values.locationIndex !== '' && values.buildingIndex !== ''
               && values.floorIndex !== ''
                && values.departmentIndex !== '') {
                  const data = {
                    id: response.data.data.doorId,
                    type: 'door',
                    index: values.locationIndex,
                    buildingIndex: values.buildingIndex,
                    floorIndex: values.floorIndex,
                    departmentIndex: values.departmentIndex,
                    doorIndex: values.doorIndex,
                  };
                  yield put(locationSlice.actions.getDeviceByLocationTree(data));
                }
              }
            }
          }
        }
      }
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getBuildingByLocation(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.getBuildingByLocation(values);
    if (response.data.data) {
      yield put(
        locationSlice.actions.setAddBuildingByLocation(response.data.data),
      );
    } else if (response.data === 'No Buildings found') {
      toast.error('No Buildings found');
      yield put(locationSlice.actions.setAddBuildingByLocation(''));
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getLocationTree(): any {
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.authentication.getLocation();
    if (!response.data.error) {
      yield put(locationSlice.actions.setAddLocationTree(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getBuildingByLocationTree(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getBuildingByLocation(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
      };
      yield put(locationSlice.actions.setAddBuildingByLocationTree(data));
    } else if (response.data === 'No Buildings found') {
      yield put(locationSlice.actions.setAddBuildingByLocation(''));
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getDoorByLocationTree(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getDoor(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        buildingIndex: values.buildingIndex,
      };
      yield put(locationSlice.actions.setAddDoorTree(data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else if (response.data === 'No doors found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getDepartmentByLocationTree(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getDepartment(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        buildingIndex: values.buildingIndex,
        floorIndex: values.floorIndex,
      };
      yield put(locationSlice.actions.setAddDepartmentTree(data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else if (response.data === 'No doors found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getFloorByLocationTree(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getFloor(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        buildingIndex: values.buildingIndex,
      };
      yield put(locationSlice.actions.setAddFloorTree(data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else if (response.data === 'No doors found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}

function* getDeviceByLocationTree(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getDevice(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        buildingIndex: values.buildingIndex,
        floorIndex: values.floorIndex,
        departmentIndex: values.departmentIndex,
        doorIndex: values.doorIndex,
      };
      yield put(locationSlice.actions.setAddDeviceTree(data));
    } else if (response.data === 'No devices found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getClientLocationById(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getClientLocationById(values);
    if (response.data) {
      yield put(locationSlice.actions.setClientLocationById(response.data));
    } else if (response.data === 'No devices found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getClientBuildingById(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getClientBuildingById(values);
    if (response.data) {
      yield put(locationSlice.actions.setBuildingLocationById(response.data));
    } else if (response.data === 'No devices found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getClientFloorById(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getClientFloorById(values);
    if (response.data) {
      yield put(locationSlice.actions.setFloorById(response.data));
    } else if (response.data === 'No devices found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getClientDepartmentById(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getClientDepartmentById(values);
    if (response.data) {
      yield put(locationSlice.actions.setDepartmentById(response.data));
    } else if (response.data === 'No devices found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getDoorUser(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.getDoorUser(values);
    if (response.data.user_door_info) {
      yield put(
        locationSlice.actions.setAddDoorUser(response.data.user_door_info),
      );
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getClientDoorById(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getClientDoorById(values);
    if (response.data) {
      yield put(locationSlice.actions.setDoorLocationById(response.data.data));
    } else if (response.data === 'No devices found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* editLocation(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.editLocation(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(
        locationSlice.actions.getClientLocationById(values.location_id),
      );
      yield put(locationSlice.actions.getLocationTree());
      yield put(locationSlice.actions.getLocation());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* editBuilding(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.editBuilding(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(
        locationSlice.actions.getClientBuildingById(values.building_id),
      );
      yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* editDoor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.editDoor(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(locationSlice.actions.getClientDoorById(values.door_id));
      yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* editSensor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.editSensor(values.data);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(locationSlice.actions.getClientDoorById(values.id));
      if (values.locationDetailIndex !== '' && values.buildingDetailIndex !== ''
      && values.floorDetailIndex !== ''
       && values.departmentDetailIndex !== '') {
        const data = {
          id: values.doorId,
          type: 'door',
          index: values.locationDetailIndex,
          buildingIndex: values.buildingDetailIndex,
          floorIndex: values.floorDetailIndex,
          departmentIndex: values.departmentDetailIndex,
          doorIndex: values.doorDetailIndex,
        };
        yield put(locationSlice.actions.getDeviceByLocationTree(data));
      }
      // yield put(locationSlice.actions.getClientLocationById(action.payload.id));
      // yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* deleteLocation(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.deleteLocation(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(locationSlice.actions.setClientLocationById([]));
      yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* deleteBuilding(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.deleteBuilding(values.data);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(locationSlice.actions.setBuildingLocationById([]));
      if (values.locationDetailIndex !== '') {
        const data = {
          id: values.locationId,
          type: 'location',
          index: values.locationDetailIndex,
        };
        yield put(locationSlice.actions.getBuildingByLocationTree(data));
      }
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* deleteDoor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.deleteDoor(values.data);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(locationSlice.actions.setDoorLocationById([]));
      if (
        values.locationDetailIndex !== ''
        && values.buildingDetailIndex !== ''
      ) {
        const data = {
          id: values.departmentId,
          type: 'department',
          index: values.locationDetailIndex,
          buildingIndex: values.buildingDetailIndex,
          floorIndex: values.floorDetailIndex,
          departmentIndex: values.departmentDetailIndex,
        };
        yield put(locationSlice.actions.getDoorByDepartmentTree(data));
      }
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* deleteSensor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.deleteSensor(values.data);
    if (!response.data.error) {
      yield put(locationSlice.actions.setSensorResponse([]));
      toast.success(response.data.msg);
      if (values.locationDetailIndex !== '' && values.buildingDetailIndex !== ''
               && values.floorDetailIndex !== ''
                && values.departmentDetailIndex !== '') {
        const data = {
          id: values.doorId,
          type: 'door',
          index: values.locationDetailIndex,
          buildingIndex: values.buildingDetailIndex,
          floorIndex: values.floorDetailIndex,
          departmentIndex: values.departmentDetailIndex,
          doorIndex: values.doorDetailIndex,
        };
        yield put(locationSlice.actions.getDeviceByLocationTree(data));
      }
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* assignUnassignedDoor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload.data;
    const response = yield Api.authentication.assignUnassignedDoor(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      if (action.payload.flag === 'location') {
        yield put(
          locationSlice.actions.getClientLocationById(action.payload.id),
        );
      } else if (action.payload.flag === 'building') {
        yield put(
          locationSlice.actions.getClientBuildingById(action.payload.id),
        );
      } else if (action.payload.flag === 'floor') {
        yield put(
          locationSlice.actions.getClientFloorById(action.payload.id),
        );
      } else if (action.payload.flag === 'department') {
        yield put(
          locationSlice.actions.getClientDepartmentById(action.payload.id),
        );
      }
      // yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getDoorLogs(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getDoorLogs(values);
    if (response.data) {
      yield put(locationSlice.actions.setDoorLogs(response.data.data));
    } else {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* resetDoorOpeningCount(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.resetDoorOpeningCount(values.data);
    if (!response.data.error) {
      toast.success(response.data.msg);
      if (values.flag === 'location') {
        yield put(locationSlice.actions.getClientLocationById(values.id));
      }
      if (values.flag === 'building') {
        yield put(locationSlice.actions.getClientBuildingById(values.id));
      }
      if (values.flag === 'floor') {
        yield put(locationSlice.actions.getClientFloorById(values.id));
      }
      if (values.flag === 'department') {
        yield put(locationSlice.actions.getClientDepartmentById(values.id));
      }
      // yield put(locationSlice.actions.setDoorLocationById([]));
      // yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* addDepartment(action: any): any {
  // const { treeView } = useSelector((state: RootState) => state.location);
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.addDepartment(values.data);
    if (!response.data.error) {
      toast.success('Department added successfully');
      yield put(
        locationSlice.actions.setaddDepartmentResponse(response.data.data),
      );
      if (values.treeView[action.payload.locationIndex].children.length > 0) {
        if (
          values.treeView[action.payload.locationIndex].children[
            action.payload.buildingIndex
          ].children.length > 0
        ) {
          if (
            values.treeView[action.payload.locationIndex].children[
              action.payload.buildingIndex
            ].children[action.payload.floorIndex].children.length > 0
          ) {
            if (
              values.locationIndex !== ''
              && values.buildingIndex !== ''
              && values.floorIndex !== ''
            ) {
              const data = {
                id: response.data.data.floorId,
                type: 'department',
                index: values.locationIndex,
                buildingIndex: values.buildingIndex,
                floorIndex: values.floorIndex,
              };
              yield put(locationSlice.actions.getDepartmentByLocationTree(data));
            }
          }
        }
      }
    } else if (response.data.error) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getDepartment(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.getDepartment(values);

    if (response.data.data) {
      yield put(locationSlice.actions.setAddDepartment(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* deleteDepartment(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.deleteDepartment(values.data);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(locationSlice.actions.setDepartmentById([]));
      if (values.locationDetailIndex !== '') {
        const data = {
          id: values.floorId,
          type: 'floor',
          index: values.locationDetailIndex,
          buildingIndex: values.buildingDetailIndex,
          floorIndex: values.floorDetailIndex,
        };
        yield put(locationSlice.actions.getDepartmentByLocationTree(data));
      }
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* addFloor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.addFloor(values.data);
    if (!response.data.error) {
      yield put(locationSlice.actions.setaddFloorResponse(response.data.data));
      toast.success('Floor added successfully');
      if (values.treeView[action.payload.locationIndex].children.length > 0) {
        if (
          values.treeView[action.payload.locationIndex].children[
            action.payload.buildingIndex
          ].children.length > 0
        ) {
          if (values.locationIndex !== '' && values.buildingIndex !== '') {
            const data = {
              id: response.data.data.clientBuildingId,
              type: 'building',
              index: values.locationIndex,
              buildingIndex: values.buildingIndex,
            };
            yield put(locationSlice.actions.getFloorByLocationTree(data));
          }
        }
      }
      // yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.error) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getFloor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.authentication.getFloor(values);

    if (response.data.data) {
      yield put(locationSlice.actions.setAddFloor(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* deleteFloor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.deleteFloor(values.data);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(locationSlice.actions.setFloorById([]));
      if (values.locationDetailIndex !== '' && values.buildingDetailIndex !== 0) {
        const data = {
          id: values.buildingId,
          type: 'building',
          index: values.locationDetailIndex,
          buildingIndex: values.buildingDetailIndex,
        };
        yield put(locationSlice.actions.getFloorByLocationTree(data));
      }
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getDoorByDepartmentTree(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.getDoor(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        buildingIndex: values.buildingIndex,
        floorIndex: values.floorIndex,
        departmentIndex: values.departmentIndex,
      };
      yield put(locationSlice.actions.setAddDoorTree(data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else if (response.data === 'No doors found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* editDepartment(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.editDepartment(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(
        locationSlice.actions.getClientDepartmentById(values.building_id),
      );
      // yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* editFloor(action: any): any {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.authentication.editFloor(values);
    if (!response.data.error) {
      toast.success(response.data.msg);
      yield put(
        locationSlice.actions.getClientFloorById(values.floor_id),
      );
      // yield put(locationSlice.actions.getLocationTree());
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* locationSaga() {
  yield takeEvery('location/addLocation', addLocation);
  yield takeEvery('location/getBuilding', getBuilding);
  yield takeEvery('location/getLocation', getLocation);
  yield takeEvery('location/addBuilding', addBuilding);
  yield takeEvery('location/addDoor', addDoor);
  yield takeEvery('location/getDoor', getDoor);
  yield takeEvery('location/getDoorByDepartment', getDoorByDepartment);
  yield takeEvery('location/getDevice', getDevice);
  yield takeEvery('location/addDevice', addDevice);
  yield takeEvery('location/getBuildingByLocation', getBuildingByLocation);
  yield takeEvery('location/getLocationTree', getLocationTree);
  yield takeEvery(
    'location/getBuildingByLocationTree',
    getBuildingByLocationTree,
  );
  yield takeEvery('location/getDoorByLocationTree', getDoorByLocationTree);
  yield takeEvery(
    'location/getDepartmentByLocationTree',
    getDepartmentByLocationTree,
  );
  yield takeEvery('location/getFloorByLocationTree', getFloorByLocationTree);
  yield takeEvery('location/getDeviceByLocationTree', getDeviceByLocationTree);
  yield takeEvery('location/getClientLocationById', getClientLocationById);
  yield takeEvery('location/getClientBuildingById', getClientBuildingById);
  yield takeEvery('location/getClientFloorById', getClientFloorById);
  yield takeEvery('location/getClientDepartmentById', getClientDepartmentById);
  yield takeEvery('location/getDoorUser', getDoorUser);
  yield takeEvery('location/getClientDoorById', getClientDoorById);
  yield takeEvery('location/editLocation', editLocation);
  yield takeEvery('location/editBuilding', editBuilding);
  yield takeEvery('location/editDoor', editDoor);
  yield takeEvery('location/editSensor', editSensor);
  yield takeEvery('location/deleteLocation', deleteLocation);
  yield takeEvery('location/deleteBuilding', deleteBuilding);
  yield takeEvery('location/deleteDoor', deleteDoor);
  yield takeEvery('location/deleteSensor', deleteSensor);
  yield takeEvery('location/assignUnassignedDoor', assignUnassignedDoor);
  yield takeEvery('location/getDoorLogs', getDoorLogs);
  yield takeEvery('location/resetDoorOpeningCount', resetDoorOpeningCount);
  yield takeEvery('location/addDepartment', addDepartment);
  yield takeEvery('location/getDepartment', getDepartment);
  yield takeEvery('location/deleteDepartment', deleteDepartment);
  yield takeEvery('location/addFloor', addFloor);
  yield takeEvery('location/getFloor', getFloor);
  yield takeEvery('location/deleteFloor', deleteFloor);
  yield takeEvery('location/getDoorByDepartmentTree', getDoorByDepartmentTree);
  yield takeEvery('location/editDepartment', editDepartment);
  yield takeEvery('location/editFloor', editFloor);
}

export default locationSaga;
