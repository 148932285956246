/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { setTopLevelNavigation } from './navigator';
import { PrivateRoute } from './privateRoutes';
import { PublicRoute } from './publicRoutes';
import { RootState } from '../redux/store/rootReducer';
import locationSlice from '../redux/slices/location.slice';
import closeIcon from '../assets/images/icons/close-icon.svg';

const SignUp = lazy(() => import('../pages/auth/signUp/signUp'));
const SignInAs = lazy(() => import('../pages/auth/signInAs/signInAs'));
const Login = lazy(() => import('../pages/auth/login/Login'));
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
const Locations = lazy(() => import('../pages/locations/location'));
const Devices = lazy(() => import('../pages/devices/devices'));
const Users = lazy(() => import('../pages/users/users'));
const Error404 = lazy(() => import('../pages/errorPage/errorPage'));

export default function routes() {
  const dispatch = useDispatch();
  const { keys, keysPopUp } = useSelector(
    (state: RootState) => state.location,
  );
  const setKeysPopup = () => {
    dispatch(locationSlice.actions.setKeysPopUp(false));
  };
  return (
    <BrowserRouter ref={setTopLevelNavigation}>
      {keysPopUp && (

      <div
        className={`popup-wrapper fixed w-full h-full top-0 left-0 overflow-auto ${
          keysPopUp ? 'block' : 'hidden'
        }`}
      >
        <div className="popup-container absolute w-full mb-6 top-6 md:mb-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
          <div className="w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
            <div
              onClick={() => setKeysPopup()}
              className="popup-close absolute top-6 right-5 cursor-pointer"
            >
              <img src={closeIcon} />
            </div>
            <div className="popup-title font-bold text-2xl">Device Keys</div>
            <div className="popup-frm w-full float-left pt-4 pb-2">
              <div className="flex flex-col mb-3">
                <label className="font-medium text-sm">Security key 01</label>
                <input
                  className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                  placeholder="Enter Email"
                  type="text"
                  value={keys.security_key_01}
                />

              </div>

              <div className="flex flex-col mb-3">
                <label className="font-medium text-sm">Security key 02</label>
                <input
                  className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                  placeholder="Enter Name"
                  type="text"
                  value={keys.security_key_02}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      )}
      <Suspense fallback={null}>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/siginInAs" component={SignInAs} />
          <PublicRoute exact path="/signUp" component={SignUp} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/locations" component={Locations} />
          <PrivateRoute exact path="/devices" component={Devices} />
          <Route path="/users" render={() => <Users />} />
          <Route path="/location-details" render={() => <Locations />} />
          <Route path="/building-details" render={() => <Locations />} />
          <Route path="/door-details" render={() => <Locations />} />
          <Route component={Error404} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
