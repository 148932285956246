import React from 'react';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { RootState } from '../../redux/store/rootReducer';

const Spinner = () => {
  const { visible } = useSelector((state: RootState) => state.loader);
  return (
    <>
      {visible ? (
        <div className="fixed h-full w-full flex items-center justify-center bg-black bg-opacity-25" style={{ zIndex: 123456 }}>
          <ClipLoader
            css={`
              display: block;
              margin: 0 auto;
              border-color: '#0E278F';
            `}
            size={55}
            color="#00A49F"
            loading
          />
        </div>
      ) : null}
    </>
  );
};

export default Spinner;
