/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint no-unused-vars: "error" */

import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  authenticated: boolean;
  initialAuthentcationRequest: boolean;
  token: string;
  user:
    | {
        attributes: {
          email: string;
          emailVerified: boolean;
          sub: string;
        };
        id: string;
        username: string;
      }
    | undefined;
};

const initialState: InitialState = {
  user: undefined,
  authenticated: false,
  initialAuthentcationRequest: false,
  token: '',
};

export const login = (state: InitialState, action: any) => {};
export const setAuthenticatedUser = (state: InitialState, action: any) => {
  state.user = action.payload;
  state.authenticated = true;
  state.initialAuthentcationRequest = true;
};

export const logOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('adminUser');
  localStorage.removeItem('user');
};
export const clearToken = (state: InitialState) => {
  state.token = '';
};
export const signUp = (state: any, action: any) => {};
export const userSignUp = (state: any, action: any) => {};
export const checkauth = (state: InitialState) => state;

export const setAuthentication = (state: InitialState, action: any) => {
  state.token = action.payload;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login,
    userSignUp,
    signUp,
    logOut,
    clearToken,
    checkauth,
    setAuthentication,
  },
});

export default authSlice;
