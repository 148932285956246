/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
/* eslint no-extra-boolean-cast: 2 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{ pathname: '/dashboard', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
