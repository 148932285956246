/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-param-reassign */
/* eslint no-unused-vars: "error" */

import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  user: any;
};

const initialState: InitialState = {
  user: '',
};

export const getUser = () => {};
export const setUserResponse = (state: InitialState, action: any) => {
  state.user = action.payload;
};
export const inviteUser = (state: InitialState, action: any) => {
};
export const assignDoor = (state: InitialState, action: any) => {
};
export const resendInvite = (state: InitialState, action: any) => {
};
export const removeUser = (state: InitialState, action: any) => {
};
export const updateRole = (state: InitialState, action: any) => {
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser,
    setUserResponse,
    inviteUser,
    assignDoor,
    resendInvite,
    removeUser,
    updateRole,
  },
});

export default userSlice;
