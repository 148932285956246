import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import Router from './navigation/routes';
import Toast from './components/toast/toast';
import Spinner from './components/spinner/spinner';

function App() {
  return (
    <div>
      <Provider store={store}>
        <Spinner />
        <Toast />
        <Router />
      </Provider>
    </div>
  );
}

export default App;
// import React from 'react';
// import { Provider } from 'react-redux';
// import Router from './navigation/routes';
// import store from './redux/store/store';

// function App() {
//   return (
//     <div>
//       <Provider store={store}>
//         <Router />
//       </Provider>
//     </div>
//   );
// }

// export default App;

// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit
//           <code>src/App.js</code>
//           and save to reload.
//         </p>
//         {/* <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer">
//           Learn React
//         </a> */}
//       </header>
//     </div>
//   );
// }

// export default App;
